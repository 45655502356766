<template>
  <div class="text-center">
    <v-dialog
      v-model="isDialogOpen"
      width="600"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          outlined
          v-bind="attrs"
          class="w-full"
          v-on="on"
        >
          {{ $t('buttons.valuation.delete-valuation') }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title
          class="pa-0"
        >
          <v-toolbar
            color="error"
            dark
            flat
          >
            <v-toolbar-title>{{ $t('buttons.valuation.delete-valuation') }}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                icon
                dark
                @click="cancel"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>

        <v-card-text class="py-6">
          {{ $t('alerts.valuation.delete-valuation', {address}) }}
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn
            text
            @click="cancel"
          >
            {{ $t('labels.cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="error"
            text
            :loading="isDeleting"
            @click.prevent="deleteValuation"
          >
            {{ $t('buttons.valuation.delete-valuation') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DELETE_VALUATION from './queries/deleteValuation.gql'
import bus, { eventNames } from '@/lib/eventBus'

export default {
  props: {
    valuationId: {
      type: String,
      required: true
    },
    address: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isDialogOpen: false,
      isDeleting: false
    }
  },
  methods: {
    cancel () {
      this.isDialogOpen = false
    },
    async deleteValuation () {
      try {
        this.isDeleting = true
        await this.$apollo.mutate({
          mutation: DELETE_VALUATION,
          variables: { valuationId: this.valuationId }
        })
        this.$emit('deleted', this.valuationId)

        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.valuation.success-delete'), newTime: 10000 })
        this.isDialogOpen = false
      } catch (err) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.valuation.error-delete'), newTime: 10000 })
      } finally {
        this.isDeleting = false
      }
    }
  }
}
</script>
