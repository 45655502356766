<template>
  <div v-if="valuations && valuations.length>0">
    <v-expansion-panels
      v-model="panel"
      accordion
      flat
    >
      <ValuationResultItem
        v-for="(valuation, index) in valuations"
        :key="valuation.id"
        :valuation="valuation"
        :index="index"
        :panel="panel"
        :open-id="openId"
        :class="'mb-2'+ (valuation.deleted ? ' deleted': '')"
        @expand="updateOpenId"
        @deleted="onDelete"
      />
    </v-expansion-panels>
    <div
      v-if="hasMore"
      class="justify-center mt-4 d-flex"
    >
      <v-btn
        color="primary"
        @click="loadMore"
      >
        Weitere Bewertungen laden
      </v-btn>
    </div>
  </div>
</template>

<script>
import currencyMixin from '@/mixins/currency'
import ValuationResultItem from './ValuationResultItem.vue'
import GET_VALUATIONS_PAGINATED from './queries/getValuationsPaginated.gql'

const CHUNK_SIZE = 20

export default {
  components: {
    ValuationResultItem
  },
  mixins: [currencyMixin],
  props: {
    currentValuation: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      hasMore: false,
      valuations: [],
      panel: -1,
      page: 1,
      openId: ''
    }
  },
  watch: {
    currentValuation () {
      if (this.currentValuation && this.currentValuation.results) {
        if (this.valuations.find(obj => obj.id === this.currentValuation.id)) return
        this.valuations.unshift(this.currentValuation)
        this.panel = this.valuations.length - 1
        this.openId = this.valuations[0].id
      }
    }
  },
  apollo: {
    getValuationsPaginated: {
      query: GET_VALUATIONS_PAGINATED,
      fetchPolicy: 'no-cache',
      variables () {
        return {
          input: {
            page: this.page,
            itemsPerPage: CHUNK_SIZE,
            createdByAgent: true
          }
        }
      },
      skip () {
        return this.$auth.loading || !this.$auth.isAuthenticated
      },
      result ({ data }) {
        if (!data || !data.getValuationsPaginated?.valuations) return
        this.hasMore = data.getValuationsPaginated.hasMore
        this.valuations.push(...data.getValuationsPaginated.valuations)
        this.panel = 0
        this.openId = this.valuations[0].id
      }
    }
  },
  methods: {
    onDelete (id) {
      this.valuations = this.valuations.map(valuation => {
        if (valuation.id === id) {
          valuation.deleted = true
        }
        return valuation
      })
      setTimeout(() => {
        this.valuations = this.valuations.filter(valuation => valuation.id !== id)
      }, 1000)
    },
    loadMore () {
      this.page += 1
    },
    updateOpenId (id) {
      this.openId = id
    }

  }
}
</script>

<style scoped>
.deleted {
  opacity: 0;
  transition: opacity 1s;
}
</style>
